


























import { SfButton, SfImage, SfSidebar, } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import SvgImage from '@/components/General/SvgImage';
import productPlaceholder from '@storefront-ui/shared/images/product_placeholder.svg';
import AddToCartRecap from '~/components/molecules/AddToCartRecap.vue';

export default defineComponent({
  name: 'AddToCartSidebar',
  components: {
    SfSidebar,
    SfButton,
    SvgImage,
    SfImage,
    AddToCartRecap
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    articleCode: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    conditioning: {
      type: String,
      default: ''
    },
    configuration: {
      type: String,
      default: ''
    },
    quantity: {
      type: Number,
      default: 1
    },
    total: {
      type: [String, Number, Boolean],
      default: 0
    },
    taxe: {
      type: String,
      default: 'TTC'
    },
    image: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'Peintures pro'
    },
  },
  setup() {
    const { isAddToCartSidebarOpen, toggleAddToCartSidebar } = useUiState();

    return {
      isAddToCartSidebarOpen,
      toggleAddToCartSidebar,
      productPlaceholder
    };
  },
});
